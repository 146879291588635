<template>
  <div class="main">
    <el-form :class="{'formMain2':type==='2'}" class="box" :model="formData" :inline="true">
      <div class="box center flexW elFormMain">
        <el-form-item v-if="type==='2'" label="效应范围">
          <el-select @change="rangeChange" v-model="formData.range" placeholder="请选择">
            <el-option
                v-for="item in rangeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item :class="{'ml20':type==='2'}" label="监测指标">
          <el-select @change="quotaChange" v-model="formData.quota" placeholder="请选择">
            <el-option
                v-for="item in quotaList"
                :key="item.name"
                :label="item.name"
                :value="item.id"/>
          </el-select>
        </el-form-item>
        <el-form-item class="ml20" label="测点编号">
          <el-select class="numberMain" filterable v-model="formData.targetId" placeholder="请选择">
            <el-option
                v-for="item in numberList"
                :key="item.id"
                :label="item.code"
                :value="item.id"/>
          </el-select>
        </el-form-item>
        <div :class="{'timeMain':type==='2'}" class="box center ml20 timeMain">
          <span class="el-form-item__label">查询时段</span>
          <el-form-item prop="startTime">
            <el-date-picker
                placeholder="请选择时间"
                type="datetime"
                :clearable="false"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
                v-model="formData.startTime">
            </el-date-picker>
          </el-form-item>
          <span class="line">-</span>
          <el-form-item prop="endTime">
            <el-date-picker
                placeholder="请选择时间"
                type="datetime"
                :clearable="false"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
                v-model="formData.endTime">
            </el-date-picker>
          </el-form-item>
        </div>
      </div>
      <el-button @click="queryData" class="ml24 dataQuery">查询</el-button>
    </el-form>
    <template v-if="isShowMsg">
      <p class="emColor title">测点信息</p>
      <ul class="box center flexW msgList">
        <li class="box center" v-for="item in msgList.slice(0,5)" :key="item.id">
          <div class="box center labelTxt">
            <svg class="icon" aria-hidden="true">
              <use :xlink:href="item.icon"></use>
            </svg>
            <span>{{item.title}}</span>
          </div>
          <div class="contentTxt ellipsis">{{item.value}}</div>
        </li>
      </ul>
      <ul class="box center flexW msgList">
        <li class="box center" v-for="item in msgList.slice(5,10)" :key="item.id">
          <div class="box center labelTxt">
            <svg class="icon" aria-hidden="true">
              <use :xlink:href="item.icon"></use>
            </svg>
            <span>{{item.title}}</span>
          </div>
          <div class="contentTxt">{{item.value}}</div>
        </li>
      </ul>
      <div class="titleGroup mT30 box box_column horizontal">
        <div class="box center">
          <p class="emColor">数据处理</p>
          <el-radio-group @change="changeCheckbox" v-model="checkedList">
            <el-radio v-for="item of checkList" :key="item.id" :label="item.id" :disabled="item.isShow===1?false:true">{{item.label}}</el-radio>
          </el-radio-group>
        </div>
        <div v-if="isShowResult" class="box center mT10">
          <p class="emColor">处理结果</p>
          <ul class="box center">
            <span v-for="(item,index) in checkedDataList" :key="index">
              <li v-if="item.isShow">
                <span>{{item.label}}：</span>
                <span class="blueTxt">{{item.value}}条数据</span>
              </li>
            </span>
          </ul>
        </div>
      </div>
      <ul class="toolTipList box center">
        <li class="box center">
          <span class="lineColor" style="background:#8AE8FF"></span>
          <span class="pl10 fs14">原始数据</span>
        </li>
        <li v-for="(item,index) in tooltipList" :key="index" class="box center">
          <span class="lineColor" :style="'background:'+item.color"></span>
          <span class="pl10 fs14">{{item.label}}</span>
        </li>
        <li v-if="checkedList === 4 || checkedList === 5" class="box center">
          <span class="lineColor" style="background:#DBF804"></span>
          <span class="pl10 fs14">去基线数据</span>
        </li>
        <li v-if="checkedList === 5" class="box center">
          <span class="lineColor" style="background:#FF7575"></span>
          <span class="pl10 fs14">动态响应值</span>
        </li>
      </ul>
      <div id="myChart3" class="myChart"></div>
      <div v-if="checkedList === 4">
        <p class="emColor title">去基线数据</p>
        <div id="myChart4" class="myChart"></div>
      </div>
      <template v-if="checkedList===5">
        <div>
          <p class="emColor title">准静态效应</p>
          <div id="myChart5" class="myChart"></div>
        </div>
        <div>
          <p class="emColor title">动态响应项</p>
          <div id="myChart2" class="myChart"></div>
        </div>
      </template>
    </template>
    <div v-else class="noDataMain">
      <img src="../../assets/images/noData.png" alt="">
      <span>暂无数据</span>
    </div>
  </div>
</template>

<script>
import echarts from 'echarts'
let option = {
  dataZoom: [
    {
      show: true,
      realtime: true,
      start: 0,
      end: 10,
      fillerColor:'rgba(47,50,51,1)'
    },
    {
      type: 'inside',
      realtime: true,
      start: 90,
      end: 100,
    }
  ],
  tooltip:{
    show:true,
    trigger: 'axis'
  },
  grid:{
    top:'20',
    left:'20',
    right:'10',
    bottom:'40',
    containLabel:true
  },
  xAxis: {
    type: 'category',
    axisLabel:{
      color:'#fff',
      fontSize:12,
      rotate:5,
      showMinLabel:false
    },
    axisLine:{
      lineStyle:{
        color:'#9D9D9D',
        type:'solid'
      },
    },
    axisTick:{
      show:false
    },
    data: []
  },
  yAxis: [
    {
      name:'',
      nameTextStyle:{
        color:'#fff',
        padding: [0, 0, -10,10]
      },
      type: 'value',
      axisLabel:{
        color:'#fff',
        fontSize:14
      },
      axisLine:{
        lineStyle:{
          color:'#9D9D9D',
        },
      },
      splitLine:{
        lineStyle:{
          color:'#9D9D9D',
          type:'dashed'
        },
      },
      axisTick:{
        show:false
      },
    },{
      type: 'value',
      axisLine:{
        lineStyle:{
          color:'#9D9D9D'
        },
      },
      axisTick:{
        show:false
      },
    }
  ],
  series: [{
    name:'动态响应值',
    data: [],
    type: 'line',
    symbol:'none',
    smooth:true,
    itemStyle:{
      color:'#FF7575'
    },
    lineStyle:{
      width:1,
    },
  }]
}
let option4 = {
  dataZoom: [
    {
      show: true,
      realtime: true,
      start: 0,
      end: 10,
      fillerColor:'rgba(47,50,51,1)'
    },
    {
      type: 'inside',
      realtime: true,
      start: 90,
      end: 100,
    }
  ],
  tooltip:{
    show:true,
    trigger: 'axis'
  },
  grid:{
    top:'20',
    left:'20',
    right:'10',
    bottom:'40',
    containLabel:true
  },
  xAxis: {
    type: 'category',
    axisLabel:{
      color:'#fff',
      fontSize:12,
      rotate:5,
      showMinLabel:false
    },
    axisLine:{
      lineStyle:{
        color:'#9D9D9D',
        type:'solid'
      },
    },
    axisTick:{
      show:false
    },
    data: []
  },
  yAxis: [
    {
      name:'',
      nameTextStyle:{
        color:'#fff',
        padding: [0, 0, -10,10]
      },
      type: 'value',
      axisLabel:{
        color:'#fff',
        fontSize:14
      },
      axisLine:{
        lineStyle:{
          color:'#9D9D9D',
        },
      },
      splitLine:{
        lineStyle:{
          color:'#9D9D9D',
          type:'dashed'
        },
      },
      axisTick:{
        show:false
      },
    },{
      type: 'value',
      axisLine:{
        lineStyle:{
          color:'#9D9D9D'
        },
      },
      axisTick:{
        show:false
      },
    }
  ],
  series: [
    {
      name:'黄色预警',
      type:'line',
      animation: false,
      markArea: {
        data: [[{
          yAxis: null
        }, {
          yAxis: null
        }]],
        itemStyle:{
          color:'#3C3E1F',
          opacity:0
        }
      },
    },
    {
      name:'橙色预警',
      type:'line',
      animation: false,
      markArea: {
        data: [[{
          yAxis: null
        }, {
          yAxis: null
        }]],
        itemStyle:{
          color:'#36281F',
          opacity:0
        }
      }
    },
    {
      name:'红色预警',
      type:'line',
      animation: false,
      markArea: {
        data: [[{
          yAxis: null
        }, {
          yAxis: null
        }]],
        itemStyle:{
          color:'#481F1F',
          opacity:0
        }
      },
    },
    {
      name:'黄色预警',
      type:'line',
      animation: false,
      markArea: {
        data: [[{
          yAxis: null
        }, {
          yAxis: null
        }]],
        itemStyle:{
          color:'#3C3E1F',
          opacity:0
        }
      },
    },
    {
      name:'橙色预警',
      type:'line',
      animation: false,
      markArea: {
        data: [[{
          yAxis: null
        }, {
          yAxis: null
        }]],
        itemStyle:{
          color:'#36281F',
          opacity:0
        }
      }
    },
    {
      name:'红色预警',
      type:'line',
      animation: false,
      markArea: {
        data: [[{
          yAxis: null
        }, {
          yAxis: null
        }]],
        itemStyle:{
          color:'#481F1F',
          opacity:0
        }
      },
    },
    {
    name:'去基线数据',
    data: [],
    type: 'line',
    symbol:'none',
    smooth:true,
    itemStyle:{
      color:'#DBF804'
    },
    lineStyle:{
      width:1,
    },
  }]
}
let option2 = {
  dataZoom: [
    {
      show: true,
      realtime: true,
      start: 0,
      end: 10,
      fillerColor:'rgba(47,50,51,1)'
    },
    {
      type: 'inside',
      realtime: true,
      start: 90,
      end: 100,
    }
  ],
  tooltip:{
    show:true,
    trigger: 'axis'
  },
  grid:{
    top:'20',
    left:'20',
    right:'10',
    bottom:'40',
    containLabel:true
  },
  xAxis: {
    type: 'category',
    axisLabel:{
      color:'#fff',
      fontSize:12,
      rotate:5,
      showMinLabel:false
    },
    axisLine:{
      lineStyle:{
        color:'#9D9D9D',
        type:'dashed'
      },
    },
    axisTick:{
      show:false
    },
    data: []
  },
  yAxis: [
    {
      name:'',
      nameTextStyle:{
        color:'#fff',
        padding: [0, 0, -10,10]
      },
      type: 'value',
      axisLabel:{
        color:'#fff',
        fontSize:14
      },
      axisLine:{
        lineStyle:{
          color:'#9D9D9D',
        },
      },
      splitLine:{
        lineStyle:{
          color:'#9D9D9D',
          type:'dashed'
        },
      },
      axisTick:{
        show:false
      },
    },{
      type: 'value',
      axisLine:{
        lineStyle:{
          color:'#9D9D9D'
        },
      },
      axisTick:{
        show:false
      },
    }
  ],
  series: [{
    name:'去基线数据',
    data: [],
    type: 'line',
    symbol:'none',
    smooth:true,
    itemStyle:{
      color:'#DBF804'
    },
    lineStyle:{
      width:1,
    },
  },{
    name:'准静态效应',
    data: [],
    type: 'line',
    symbol:'none',
    smooth:true,
    itemStyle:{
      color:'#ea87f5'
    },
    lineStyle:{
      width:2,
    },
  }]
}
let option1 = {
  dataZoom: [
    {
      show: true,
      realtime: true,
      start: 0,
      end: 10,
      fillerColor:'rgba(47,50,51,1)'
    },
    {
      type: 'inside',
      realtime: true,
      start: 90,
      end: 100,
    }
  ],
  tooltip: {
    show:true,
    trigger: 'axis'
  },
  legend:{
    show:false,
    data: ['原始数据', '去除数据', '补齐数据', '消噪后', '基线','准静态效应'],
    selected:{'原始数据':true,'去除数据':false,'补齐数据':false,'消噪后':false,'基线':false,'准静态效应':false}
  },
  grid: {
    top:'20',
    left:'80',
    right:'10',
    bottom:'70'
  },
  xAxis: {
    type: 'category',
    // nameTextStyle:{
    //   color:'#fff',
    //   padding: [31, 0, 0,-40]
    // },
    // name:'时间',
    axisLabel:{
      color:'#fff',
      fontSize:14,
      rotate:5,
      showMinLabel:false
    },
    axisLine:{
      lineStyle:{
        color:'#9D9D9D'
      },
    },
    axisTick:{
      show:false
    },
    data:[]
  },
  yAxis: [
    {
      name:'℃',
      nameTextStyle:{
        color:'#fff',
        padding: [0, 0, -10,10]
      },
      type: 'value',
      axisLabel:{
        color:'#fff',
        fontSize:14
      },
      axisLine:{
        lineStyle:{
          color:'#9D9D9D'
        },
      },
      splitLine:{
        lineStyle:{
          color:'#9D9D9D'
        },
      },
      axisTick:{
        show:false
      },
    },{
      type: 'value',
      axisLine:{
        lineStyle:{
          color:'#9D9D9D'
        },
      },
      axisTick:{
        show:false
      },
    }
  ],
  series: [
    {
      name: '原始数据',
      type: 'line',
      symbol:'none',
      itemStyle:{
        color:'#8AE8FF',
      },
      lineStyle:{
        width:1,
        type:'dotted'
      },
      data:[]
    },
    {
      name: '去除数据',
      type: 'line',
      symbol:'none',
      itemStyle:{
        color:'#F5F5F5',
      },
      lineStyle:{
        width:1
      },
      data:[]
    },
    {
      name: '补齐数据',
      type: 'line',
      symbol:'none',
      itemStyle:{
        color:'#FF7575',
      },
      lineStyle:{
        width:1
      },
      data:[]
    },
    {
      name: '消噪后',
      type: 'line',
      symbol:'none',
      itemStyle:{
        color:'#D6A904',
      },
      lineStyle:{
        width:2
      },
      data:[]
    },
    {
      name: '基线',
      type: 'line',
      symbol:'none',
      itemStyle:{
        color:'#FF7575',
      },
      lineStyle:{
        width:2
      },
      data:[]
    },
    {
      name: '车辆作用效应',
      type: 'line',
      symbol:'none',
      itemStyle:{
        color:'#6585F8',
      },
      lineStyle:{
        width:1
      },
      data:[]
      // data: [30, 22, 11, 8, 33, 22, 11,9, 12, 13, 16, 22, 33, 44]
    }
  ]
};

import moment from 'moment'

export default {
  name: "monitorData",
  data() {
    return {
      formData:{range:1,quota:'',targetId:'',startTime:'',endTime:''},
      quotaList: [],
      rangeList: [
        {id:1,name: '整体'},
        {id:2,name: '局部'},
      ],
      numberList: [],
      msgList:[
        {id:1,title:'测点编号',icon:'#iconbianhao',value:''},
        {id:2,title:'监测类型',icon:'#iconshebeiguanli',value:''},
        {id:3,title:'监测指标',icon:'#iconzhibiao',value:''},
        {id:4,title:'数据类型',icon:'#iconshuju1',value:''},
        {id:5,title:'位置描述',icon:'#iconmiaoshu',value:''},
        {id:6,title:'传感器型号',icon:'#iconqunfengshebeixinghao',value:''},
        {id:7,title:'系数',icon:'#iconjuleixishu',value:''},
        {id:8,title:'单位',icon:'#iconwenduji',value:''},
        {id:9,title:'精度',icon:'#iconslamjingdu-gongzuojuli',value:''},
        {id:10,title:'通讯地址',icon:'#icondianhua',value:''},
      ],
      myChart2:null,
      myChart3:null,
      checkedList: [],
      checkList: [
        {id:1,label:'异常值去除',isShow:0,inter:'abnor',name:'去除数据',color:'#F5F5F5'},
        {id:2,label:'缺失数据补齐',isShow:0,inter:'refill',name:'补齐数据',color:'#FF7575'},
        {id:3,label:'消噪处理',isShow:0,inter:'filter',name:'消噪后',color:'#D6A904'},
        {id:4,label:'基线',isShow:0,inter:'based',name:'基线',color:'#FF7575'},
        {id:5,label:'车辆作用效应',isShow:0,inter:'nocar',name:'准静态效应',color:'#ea87f5'}
      ],
      checkedDataList: [
        {id:1,label:'异常值去除',isShow:false,value:null},
        {id:2,label:'缺失数据补齐',isShow:false,value:null}
      ],
      tooltipList:[],
      type:'',
      quotaInterfaceList:['environment','effect','load','derive'],
      isShowMsg: false,
      pageSize:5000, // 折线图显示多少条数据
      sort:0, //排序(0:正序，1:倒序)
      loadingPage:null,
      isShowResult:false
    }
  },
  watch:{
    $route(to){
      this.type = to.query.type
      this.clearData()
      this.getTime()
      this.getQuotaList()
    }
  },
  mounted() {
    this.type = this.$route.query.type
    this.projectId = this.$store.state.projectId
    this.getTime()
    this.getQuotaList()

    if(this.type ==1){
      // 环境类监测数据
      // this.$router.push('/whole');
        this.$router.push({
        path: '/whole',
        query: {
          type:'1'
        }
      })
    }
    if(this.type ==2){
      // 效应类监测数据
      // this.$router.push('/whole');
        this.$router.push({
        path: '/whole',
        query: {
          type:'2'
        }
      })
    }
    if(this.type ==3){
      // 荷载类监测数据
      // this.$router.push('/whole');
        this.$router.push({
        path: '/whole',
        query: {
          type:'3'
        }
      })
    }


  },
  methods: {
    getTime () {
      // this.formData.endTime = new Date().Format('yyyy-MM-dd hh:mm')
      // this.formData.startTime = new Date(new Date().getTime() - 24*60*60*1000).Format('yyyy-MM-dd hh:mm')
      var end = new Date().getTime();
      var end1  = new Date(end);
      console.log('end1', end1);
      
      this.formData.endTime = moment(end1).format('yyyy-MM-DD hh:mm')
      console.log('end', this.formData.endTime);

      // var start = new Date().getTime();
      var start  = new Date(end - (24*60*60*1000));
      this.formData.startTime = moment(start).format('yyyy-MM-DD hh:mm')
      console.log('end', this.formData.startTime);
      
    },
    changeCheckbox (val) {
      this.checkedList = val
      this.initEcharts3()
    },
    rangeChange () { // 效应范围切换
      this.formData.quota = ''
      this.formData.targetId = ''
      this.getQuotaList()
    },
    quotaChange () { // 监测指标切换
      this.formData.targetId = ''
      this.getNumberList()
    },
    queryData () { // 点击查询按钮
      if (!this.formData.targetId) return
      this.getTargetInfo() // 获取传感器信息
      this.getCheckList() // 获取多选框列表
      this.getCheckedDataInfo()
    },
    clearData () {
      this.formData = {range:1,quota:'',targetId:'',startTime:'',endTime:''}
      this.isShowMsg = false
    },
    initEcharts (xData,yData) {
      this.myChart1 = echarts.init(document.getElementById('myChart1'));
      let thisOption = {...option}
      thisOption.series[0].lineStyle.type='dotted'
      thisOption.series[0].data=yData
      thisOption.series[0].name='原始数据'
      thisOption.xAxis.data=xData
      thisOption.yAxis[0].name=this.unit
      let max = Math.ceil(Math.max.apply(null,yData))
      let min = Math.ceil(Math.min.apply(null,yData))
      thisOption.yAxis[0].max = max
      thisOption.yAxis[0].min = min
      this.myChart1.setOption(thisOption)
      window.addEventListener("resize", () => { this.myChart1.resize();});
    },
    initEcharts2 () {
      let yData = option.series[0].data
      let max = Math.max.apply(null,yData).toFixed(3)
      let min = Math.min.apply(null,yData).toFixed(3)
      option.yAxis[0].max = max
      option.yAxis[0].min = min
      this.myChart2 = echarts.init(document.getElementById('myChart2'));
      this.myChart2.setOption(option)
      window.addEventListener("resize", () => { this.myChart2.resize();});
    },
    initEcharts4 () { //去基线数据
      let yData = option4.series[6].data
      let max = Math.max.apply(null,yData).toFixed(3)
      let min = Math.min.apply(null,yData).toFixed(3)
      option4.yAxis[0].max = max
      option4.yAxis[0].min = min
      this.myChart4 = echarts.init(document.getElementById('myChart4'));
      this.myChart4.setOption(option4)
      window.addEventListener("resize", () => { this.myChart4.resize();});
    },
    initEcharts5 () { //准静态效应
      let yData = option2.series[0].data
      let yData2 = option2.series[1].data
      let max = Math.max.apply(null,yData).toFixed(3)
      let min = Math.min.apply(null,yData).toFixed(3)
      let max2 = Math.max.apply(null,yData2).toFixed(3)
      let min2 = Math.min.apply(null,yData2).toFixed(3)
      option2.yAxis[0].max = Math.max.apply(null,[max,max2])
      option2.yAxis[0].min = Math.min.apply(null,[min,min2])
      this.myChart5 = echarts.init(document.getElementById('myChart5'));
      this.myChart5.setOption(option2)
      window.addEventListener("resize", () => { this.myChart5.resize();});
    },
    initEcharts3 () {
      this.myChart3 = echarts.init(document.getElementById('myChart3'));
      this.changeLine()
      option1.yAxis[0].name=this.unit
      let yData = option1.series[0].data
      let yData2 = !this.checkedList?[]:option1.series[this.checkedList].data
      let max = Math.max.apply(null,yData).toFixed(3)
      let max2 = yData2.length>0?Math.max.apply(null,yData2).toFixed(3):max
      let min = Math.min.apply(null,yData).toFixed(3)
      let min2 = yData2.length>0?Math.min.apply(null,yData2).toFixed(3):min
      console.log(max)
      console.log(max2)
      console.log(min)
      console.log(min2)
      option1.yAxis[0].max = Math.max.apply(null,[max,max2])
      option1.yAxis[0].min = Math.min.apply(null,[min,min2,max>max2?max:max2])
      console.log(option1.yAxis[0].max)
      console.log(option1.yAxis[0].min)
      this.myChart3.setOption(option1,true)
      window.addEventListener("resize", () => { this.myChart3.resize();});
    },
    getQuotaList () {
      let type = this.type
      let params = {}
      if (type === '2') {
        params.effectClassify = this.formData.range
      }
      this.$axios.get(`${this.baseURL}base/baseTargetType/${this.quotaInterfaceList[type-1]}`,{params}).then(res => {
        res = res.data
        if (res.errCode === '200') {
          this.quotaList = res.data
          this.formData.quota = res.data[0].id
          this.getNumberList()
        } else {
          this.$message.error(res.errMsg)
        }
      })
    },
    getNumberList () {
      this.$axios.get(`${this.baseURL}base/baseTarget/type/${this.projectId}/${this.formData.quota}`).then(res => {
        res = res.data
        if (res.errCode === '200') {
          this.numberList = res.data
          this.formData.targetId = res.data[0].id
        } else {
          this.$message.error(res.errMsg)
        }
      })
    },
    getTargetInfo () { // 获取传感器信息
      this.$axios.get(`${this.baseURL}base/baseTarget/${this.formData.targetId}`).then(res => {
        res = res.data
        let data = res.data
        let msgList = this.msgList
        if (res.errCode === '200') {
          msgList[0].value = data.code
          msgList[1].value = data.typeGroupName
          msgList[2].value = data.typeName
          msgList[3].value = data.staticDynamicSelected === 1?'静态效应':(data.staticDynamicSelected === 2?'动态效应':'振动响应') //动静分离(1：静态，2：动态，3：振动)
          msgList[4].value = data.siteName
          msgList[5].value = data.model
          msgList[6].value = data.coefficient
          msgList[7].value = data.unit
          msgList[8].value = data.precisions
          this.unit = data.unit
          msgList[9].value = data.serialNumber
          this.msgList = msgList
          this.isShowMsg = true
          this.getHistoryData() // 获取原始监测数据折线
        } else {
          this.$message.error(res.errMsg)
        }
      })
    },
    getCheckedDataInfo () { // 获取传感器信息
      let params = {
        targetId:this.formData.targetId,
        startTime:this.formData.startTime + ":00",
        endTime:this.formData.endTime+ ":00"
      }
      this.$axios.get(`${this.baseURL}data/history/resultCount/${this.formData.targetId}`,{params}).then(res => {
        res = res.data
        let data = res.data
        let checkedDataList = this.checkedDataList
        if (res.errCode === '200') {
          checkedDataList[0].value = data.abnor
          checkedDataList[1].value = data.refill
          this.checkedDataList = checkedDataList
        } else {
          this.$message.error(res.errMsg)
        }
      })
    },
    getCheckList () { // 多选框列表
      let targetId = this.formData.targetId
      this.$axios.get(`${this.baseURL}setttings/settingsDataClean/option/${targetId}`).then(res => {
        res = res.data
        let data = res.data
        let checkList = this.checkList
        let checkedList = ''
        if (res.errCode === '200') {
          checkList[0].isShow = data.outlierSelected
          checkList[1].isShow = data.patchSelected
          checkList[2].isShow = data.denoisingSelected
          checkList[3].isShow = data.baselineSelected
          checkList[4].isShow = data.staticDynamicSeparationSelected
          for (let i = 0;i<checkList.length;i++) {
            let item = checkList[i]
            if (item.isShow === 1) {
              checkedList = item.id
              this.checkedDataList[0].isShow = true
              break
            }
          }
          this.getCarData()
          this.getBasedData()
            .then((data)=>{
              let xData = []
              let yData = []
              for (let i = 0;i<data.length;i++) {
                let item = data[i]
                if (i>0 && new Date(item.time).getDate() === new Date(data[i-1].time).getDate()) {
                  // xData.push(new Date(item.time).Format('hh:mm:ss'))
                  xData.push(moment(item.time, "YYYYMMDDHHmmssSSS").format("HH:mm:ss"));
                }else{
                  xData.push(item.time)
                }
                yData.push(item.data[0])
              }
              option2.xAxis.data=xData
              option2.series[0].data = yData
              this.getnoCarData()
            })

          this.checkedList = checkedList
          this.checkList = checkList
        } else {
          this.$message.error(res.errMsg)
        }
      })
    },
    getHistoryData () { // 获取历史监测数据
      this.loadingPage = this.$loading({
        lock: true,
        text: '正在加载...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let targetId = this.formData.targetId
      let params = {
        targetId,
        sort:0, //排序(0:正序，1:倒序)
        startTime:this.formData.startTime+':00',
        endTime:this.formData.endTime+':00',
        size:this.pageSize
      }
      this.$axios.get(`${this.baseURL}data/history/raw/${targetId}`,{params}).then(res => {
        res = res.data
        let data = res.data
        let xData = []
        let yData = []
        console.log('原始数据')
        console.log(data.length)
        if (res.errCode === '200') {
          for (let i = 0;i<data.length;i++) {
            let item = data[i]
            if (i>0 && new Date(item.time).getDate() === new Date(data[i-1].time).getDate()) {
              // xData.push(new Date(item.time).Format('hh:mm:ss'))
              xData.push(moment(item.time, "YYYYMMDDHHmmssSSS").format("HH:mm:ss"));
            }else{
              xData.push(item.time)
            }
            yData.push(item.data[0])
          }
          option1.series[0].data = yData
          option1.xAxis.data = xData
          let axiosArr = []
          let axios1 = this.getAbnormalData()
          let axios2 = this.getRefillData()
          let axios3 = this.getFilterData()
          let axios4 = this.getBaseDataLine()
          let axios6 = this.getLimitData() // 获取限值
          axiosArr.push(axios1,axios2,axios3,axios4,axios6)
          Promise.all(axiosArr).then(res=>{
            setTimeout(()=>{
              this.loadingPage.close()
              this.initEcharts3()
            },100)
          })
        } else {
          this.$message.error(res.errMsg)
          this.isShowMsg = false
          this.loadingPage.close()
        }
      })
    },
    changeLine () {
      let checkedList = this.checkedList
      let checkedDataList = this.checkedDataList
      let legendSel = {'原始数据':true,'去除数据':false,'补齐数据':false,'消噪后':false,'基线':false,'车辆作用效应':false}
      let checkList = this.checkList
      let tooltipList = []
      for (let item of checkedDataList) {
        item.isShow = false
      }
      switch (checkedList) {
        case 1:
          legendSel['去除数据'] = true
          checkedDataList[0].isShow = true
          break;
        case 2:
          legendSel['补齐数据'] = true
          // checkedDataList[1].isShow = true //杨博士和王博士商量先不显示，所以目前设置为false
          checkedDataList[1].isShow = false //杨博士和王博士商量先不显示，所以目前设置为false
          break;
        case 3:
          legendSel['消噪后'] = true
          break;
        case 4:
          legendSel['基线'] = true
            this.initEcharts4()
          break;
        case 5:
          legendSel['车辆作用效应'] = true
          this.$nextTick(()=>{
            this.initEcharts5()
            this.initEcharts2()
          })
          break;
      }
      this.isShowResult = (!checkedDataList[0].isShow && !checkedDataList[1].isShow)?false:true
      this.checkedDataList = checkedDataList
      option1.legend.selected = legendSel
      if (!checkedList) {
        this.tooltipList = tooltipList
        return
      }
      let item = checkList[checkedList-1]
      let tooltip = {
        color:item.color,
        label:item.name
      }
      tooltipList.push(tooltip)
      this.tooltipList = tooltipList
    },
    getAbnormalData () { // 获取去除异常值数据
      return new Promise((resolve)=>{
        let params = {
          targetId:this.formData.targetId,
          sort:this.sort,
          startTime:this.formData.startTime+':00',
          endTime:this.formData.endTime+':00',
          size:this.pageSize
        }
        this.$axios.get(`${this.baseURL}data/history/abnor/${this.formData.targetId}`,{params}).then(res => {
          res = res.data
          let data = res.data
          let yData = []
          console.log('获取去除异常值数据')
          console.log(data.length)
          if (res.errCode === '200') {
            for (let item of data) {
              yData.push(item.data[0])
            }
            // yData=[1, 100, 92, 99, 110, 116, 90,90, 100, 92, 99, 110, 116, 90]
            option1.series[1].data = yData
            resolve()
          } else {
            this.$message.error(res.errMsg)
          }
        })
      })
    },
    getRefillData () { // 获取缺失数据补齐数据
      return new Promise((resolve)=>{
        let params = {
          targetId:this.formData.targetId,
          sort:this.sort,
          startTime:this.formData.startTime+':00',
          endTime:this.formData.endTime+':00',
          size:this.pageSize
        }
        this.$axios.get(`${this.baseURL}data/history/refill/${this.formData.targetId}`,{params}).then(res => {
          res = res.data
          let data = res.data
          let yData = []
          console.log('缺失数据补齐数据')
          console.log(data.length)
          if (res.errCode === '200') {
            for (let item of data) {
              yData.push(item.data[0])
            }
            // yData=[40, 132, 101, 134, 90, 110, 119,120, 132, 101, 134, 90, 110, 119]
            option1.series[2].data = yData
            resolve()
          } else {
            this.$message.error(res.errMsg)
          }
        })
      })
    },
    getFilterData () { // 获取消噪处理数据
      return new Promise((resolve)=>{
        let params = {
          targetId:this.formData.targetId,
          sort:this.sort,
          startTime:this.formData.startTime+':00',
          endTime:this.formData.endTime+':00',
          size:this.pageSize
        }
        this.$axios.get(`${this.baseURL}data/history/filter/${this.formData.targetId}`,{params}).then(res => {
          res = res.data
          let data = res.data
          let yData = []
          console.log('获取消噪处理数据')
          console.log(data.length)
          if (res.errCode === '200') {
            for (let item of data) {
              yData.push(item.data[0])
            }
            // yData=[60, 70, 80, 67, 88, 99, 66,77, 88, 99, 66, 77, 88, 99]
            option1.series[3].data = yData
            resolve()
          } else {
            this.$message.error(res.errMsg)
          }
        })
      })
    },
    getBaseDataLine () { // 获取基线数据
      return new Promise((resolve)=>{
        let params = {
          targetId:this.formData.targetId,
          sort:this.sort,
          startTime:this.formData.startTime+':00',
          endTime:this.formData.endTime+':00',
          size:this.pageSize
        }
        this.$axios.get(`${this.baseURL}data/history/base/${this.formData.targetId}`,{params}).then(res => {
          res = res.data
          let data = res.data
          let yData = []
          console.log('基线数据')
          console.log(data)
          if (res.errCode === '200') {
            for (let item of data) {
              yData.push(item.data[0])
            }
            // yData=[60, 60, 60, 60, 60, 60, 60,60, 60, 60, 60, 60, 60, 60]
            option1.series[4].data = yData
            resolve()
          } else {
            this.$message.error(res.errMsg)
          }
        })
      })
    },
    getBasedData () { // 获取基线校正数据
      return new Promise((resolve)=>{
        let params = {
          targetId:this.formData.targetId,
          sort:this.sort,
          startTime:this.formData.startTime+':00',
          endTime:this.formData.endTime+':00',
          size:this.pageSize
        }
        this.$axios.get(`${this.baseURL}data/history/based/${this.formData.targetId}`,{params}).then(res => {
          res = res.data
          let data = res.data
          let yData = []
          let xData = []
          console.log('去基线数据')
          console.log(data)
          if (res.errCode === '200') {
            for (let i = 0;i<data.length;i++) {
              let item = data[i]
              if (i>0 && new Date(item.time).getDate() === new Date(data[i-1].time).getDate()) {
                // xData.push(new Date(item.time).Format('hh:mm:ss'))
                xData.push(moment(item.time, "YYYYMMDDHHmmssSSS").format("YYYY-MM-DD HH:mm:ss SSS"));

              }else{
                xData.push(item.time)
              }
              yData.push(item.data[0])
            }
            option4.series[6].data = yData
            option4.xAxis.data=xData
            resolve(data)
          } else {
            this.$message.error(res.errMsg)
          }
        })
      })
    },
    getnoCarData () { // 获取车辆作用效应数据
      return new Promise((resolve)=>{
        let params = {
          targetId:this.formData.targetId,
          sort:this.sort,
          startTime:this.formData.startTime+':00',
          endTime:this.formData.endTime+':00',
          size:this.pageSize
        }
        this.$axios.get(`${this.baseURL}data/history/nocar/${this.formData.targetId}`,{params}).then(res => {
          res = res.data
          let data = res.data
          let yData1 = []
          console.log('车辆作用效应')
          console.log(data.length)
          if (res.errCode === '200') {
            for (let item of data) {
              yData1.push(item.data[0])
            }
            option2.series[1].data = yData1
            resolve()
          } else {
            this.$message.error(res.errMsg)
          }
        })
      })
    },
    getLimitData () { // 获取限值范围
      return new Promise((resolve)=>{
        let targetId = this.formData.targetId
        this.$axios.get(`${this.baseURL}setttings/settingsThresholdQuota/option/${targetId}`).then(res => {
          res = res.data
          let data = res.data
          // if (!data || data.length===0) return
          /* data = {
            "alower": 60, // 正一下限
            "aupper": 80, // 正一上限
            "blower": 80, // 正二下限
            "bupper": 100, // 正二上限
            "clower": 100, // 正三下限
            "cupper": 120, // 正三上限
            "dlower": 40, //负一下限
            "dupper": 60, //负一上限
            "elower": 20, //负二下限
            "eupper": 40, //负二上限
            "flower": 0, //负三下限
            "fupper": 20, //负三上限
          }*/
          if (res.errCode === '200') {
            // 正 黄色预警
            option4.series[0].markArea.data[0][0].yAxis = data.alower || 0
            option4.series[0].markArea.data[0][1].yAxis = data.aupper || 0
            option4.series[0].markArea.itemStyle.opacity = 0.56
            // 正 橙色预警
            option4.series[1].markArea.data[0][0].yAxis = data.blower || 0
            option4.series[1].markArea.data[0][1].yAxis = data.bupper || 0
            option4.series[1].markArea.itemStyle.opacity = 0.56
            // 正 红色预警
            option4.series[2].markArea.data[0][0].yAxis = data.clower || 0
            option4.series[2].markArea.data[0][1].yAxis = data.cupper || 0
            option4.series[2].markArea.itemStyle.opacity = 0.56
            // 负 黄色预警
            option4.series[3].markArea.data[0][0].yAxis = data.dlower || 0
            option4.series[3].markArea.data[0][1].yAxis = data.dupper || 0
            option4.series[3].markArea.itemStyle.opacity = 0.56
            // 负 橙色预警
            option4.series[4].markArea.data[0][0].yAxis = data.elower || 0
            option4.series[4].markArea.data[0][1].yAxis = data.eupper || 0
            option4.series[4].markArea.itemStyle.opacity = 0.56
            // 负 红色预警
            option4.series[5].markArea.data[0][0].yAxis = data.flower || 0
            option4.series[5].markArea.data[0][1].yAxis = data.fupper || 0
            option4.series[5].markArea.itemStyle.opacity = 0.56
            resolve()
          } else {
            this.$message.error(res.errMsg)
          }
        })
      })
    },
    getCarData () { // 获取车辆动态效应数据
      let params = {
        targetId:this.formData.targetId,
        sort:this.sort,
        startTime:this.formData.startTime+':00',
        endTime:this.formData.endTime+':00',
        size:this.pageSize
      }
      this.$axios.get(`${this.baseURL}data/history/car/${this.formData.targetId}`,{params}).then(res => {
        res = res.data
        let data = res.data
        console.log('获取车辆动态效应数据')
        let xData = []
        let yData = []
        if (res.errCode === '200') {
          for (let i = 0;i<data.length;i++) {
            let item = data[i]
            if (i>0 && new Date(item.time).getDate() === new Date(data[i-1].time).getDate()) {
              xData.push(new Date(item.time).Format('hh:mm:ss'))
            }else{
              xData.push(item.time)
            }
            yData.push(item.data[0])
          }
          option.xAxis.data=xData
          option.series[0].data = yData
        } else {
          this.$message.error(res.errMsg)
        }
      })
    },
  }
}
</script>
<style scoped lang="scss">
  .main{
    overflow-y: auto;
    ::v-deep .el-form{
      .ml20{
        margin-left: 20px;
      }
      .el-form-item__label{
        font-size: 16px;
        font-weight: 500;
        color:#fff;
        text-align: left;
        line-height: 30px;
        width: 102px;
        padding:0;
      }
      .el-form-item{
        margin-bottom: 0;
        display: flex;
        &.mB20{
          margin-bottom: 20px;
        }
        .el-form-item__content{
          margin-left: 0 !important;
          line-height: 30px;
        }
      }
      .line{
        padding: 0 10px;
      }
      .numberMain{
        .el-input{
          width: 170px;
        }
      }
      .el-input{
        width: 134px;
        height: 30px;
        .el-input__inner{
          font-size: 16px;
          color:#fff;
        }
        .el-select__caret{
          line-height: 30px;
        }
        &.el-date-editor{
          width: 158px;
          .el-input__inner{
            padding:0 0 0 8px;
            font-size: 14px;
          }
          .el-input__prefix{
            display: none;
          }
        }

      }
      .el-button.ml24{margin-left: 24px;}
    }
    .title{margin:14px 0 8px 0;}
    .msgList{

      li{
        height: 48px;
        line-height: 48px;
        margin-top: 4px;
        &:first-child{
          .contentTxt{width: 170px;}
        }
        .labelTxt{
          padding-left: 20px;
          background: rgba(48, 56, 58, 0.69);
          width: 131px;
          span{padding-left: 4px;}
        }
        .contentTxt{
          font-weight: 500;
          padding-left: 16px;
          width: 134px;
          background: rgba(34,39,40, 0.41);
          height: 48px;
        }
        &:nth-child(5){
          flex:1;
          .contentTxt{
            flex: 1;
            max-width: 399px;
          }
        }
      }
    }
    .myChart{
      //height: 220px;
      height: 260px;
      overflow: hidden;
    }
    .el-checkbox{
      margin-left: 38px;
      margin-right: 0;
    }
    .toolTipList{
      justify-content: flex-end;
      margin-top: 26px;
      li{
        margin-left: 14px;
        &:first-child{
          margin-left: 0;
        }
        .lineColor{
          width:5px;
          height: 12px;
          //background-color: #8AE8FF;
        }
        .pl10{
          padding-left: 8px;
        }
      }
    }
    .titleGroup{
      height: 96px;
      background: rgba(40, 40, 40, 0.45);
      border: 1px solid #33393C;
      padding:10px 0 10px 40px;
      li{padding-left: 38px;}
      .blueTxt{color:#8AE8FF;}
    }
    ::v-deep .el-radio-group{
      margin-left: 38px;
      .el-radio__inner{
        width: 16px;
        height: 16px;
        background-color: transparent;
        border:1px solid #787878;
        &:after{
          width: 6px;
          height: 6px;
        }
      }
      .is-disabled{
        .el-radio__label{
          color:#606266;
        }
      }
      .el-radio__label{
        color:#fff;
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
  //@media screen and (max-width:1540px) {
  @media screen and (max-width:1880px) {
    .main{
      ::v-deep .el-form{
        .el-form-item__label {
          font-size:14px;
          width: 94px;
        }
        .ml20{
          margin-left: 0px;
        }
        .numberMain{
          .el-input{
            width: 150px;
          }
        }
        .el-input{
          width: 126px;
          height: 30px;
          .el-input__inner{
            font-size: 14px;
          }

        }
        .numberMain.el-select{
          .el-input{
            .el-input__inner{
              padding:0 0 0 6px;
            }
            .el-input__icon{
              width: 10px;
            }
          }
        }
        button.el-button{
          width: 90px;
          height: 30px;
          flex-shrink: 0;
          &.ml24{margin-left: 10px;}
        }
      }
    }
  }
  @media screen and (max-width: 1710px) {
    .formMain2{
      .elFormMain{
        width: 724px;
      }
      .timeMain{
        margin-top: 20px;
      }
    }
  }
</style>